/**
 * @summary Functions specific to the login page
 */

import { aspControlValidationInit, customValidationInit } from "./validation/common.js";
import jQuery from 'jquery';
const $: JQueryStatic = jQuery;

/**
 * @summary Device Print Functionality.
 * @see /includes/pm_fp.js
 * 
 * Can't pull this in to ts yet since it's also used by newAccountSecurity.aspx
 */
function add_deviceprint(): string {
  return (globalThis as any).add_deviceprint()
}

/**
 * @summary Show an alert if cookies are disabled
 */
export function alertIfCookiesDisabled() {
  if (!navigator.cookieEnabled) {
    alert("You need to enable cookies for this site to load properly.")
  }
}

/**
 * @summary Do everything needed to show the login page.
 */
export default function InitializeLoginPage() {
  customValidationInit(false);
  aspControlValidationInit();
  //$(document).tooltip(); // JQuery-UI does not play well with TypeScript!

  // Populate the device print
  $("[id$='hdndevicePrint']").val(add_deviceprint());

  alertIfCookiesDisabled();
}
