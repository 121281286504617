

/**
 * @brief Go to a specific page via HTTP Post
 * @param uri The uri to go to.  Including any query parameters
 */
export function post(uri: string) {
    // https://stackoverflow.com/a/133997
    let form = document.createElement("form");
    form.method = "POST";
    form.action = uri;
    document.body.appendChild(form);
    form.submit();
}

/**
 * @brief Log the user out
 * @param fromInactivity If the user was logged out due to inactivity
 */
export function logout(fromInactivity: Boolean = false) {
    if (fromInactivity) {
        post("/Identity/Account/Logout?reason=Inactivity");
        return;
    }
    post("/Identity/Account/Logout");
}

export default logout;
