// Common validation function definition / references

/**
 * @summary Initialize custom validation (used on most/all forms)
 * @see /includes/js/custom.ValidationInit.js for the original function
 */
export function customValidationInit(onSubmit: boolean) {
  (globalThis as any).customValidationInit(onSubmit)
}

/**
 * @summary asp specific control validation - checkBox, radioButton, radioButtonList
 * @see /includes/js/custom.ValidationInit.js for the original function
 */
export function aspControlValidationInit() {
  (globalThis as any).aspControlValidationInit()
}
